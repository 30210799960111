<template>
  <el-form
    :model="dataForm"
    ref="form"
    label-width="100px"
    class="login-form">
    <el-form-item 
      label="姓名"
      prop="name"
      required>
      <el-input
        v-model="dataForm.name">
      </el-input>
    </el-form-item>
    <el-form-item
      label="性别"
      prop="sex"
      required>
      <el-radio v-model="dataForm.sex" label="男">男</el-radio>
      <el-radio v-model="dataForm.sex" label="女">女</el-radio>
    </el-form-item>
    <el-form-item
      label="身份证号"
      prop="citizenId"
      required>
      <el-input
        placeholder="22xxxxxxxxxxxxxxxx"
        v-model="dataForm.citizenId">
      </el-input>
      <div style="font-size:11px;line-height:20px">身份证号默认为吉林省22开头，例如：22xxxxxxxxxxxxxxxx。非吉林省身份证学员，请联系吉林省执业药师协会，核实后再注册学习。</div>
    </el-form-item>
    <el-form-item
      label="证书类型"
      prop="certType"
      required>
        <el-select 
          v-model="dataForm.certType" 
          placeholder="请选择">
          <el-option
            v-for="item in certTypeList"
            :key="item"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
    </el-form-item>
    <el-form-item 
      label="专业"
      prop="major"
      required>
        <el-select 
          v-model="dataForm.major" 
          placeholder="请选择">
          <el-option
            v-for="item in majorList"
            :key="item"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
    </el-form-item>
    <el-form-item
      label="证书号"
      prop="certNo"
      required>
      <el-input
        v-model="dataForm.certNo">
      </el-input>
    </el-form-item>
    <el-form-item
      label="证书2(双证)"
      prop="certNo2">
      <el-input
        v-model="dataForm.certNo2">
      </el-input>
    </el-form-item>
    <el-form-item
      label="省市区"
      prop="ssq"
      required>
      <el-cascader
        v-model="dataForm.ssq"
        :options="ssqData">
      </el-cascader>
    </el-form-item>
    <el-form-item
      label="详细地址"
      prop="address"
      required>
      <el-input
        type="textarea"
        :rows="3"
        v-model="dataForm.address">
      </el-input>
    </el-form-item>
    <el-form-item
      label="邮箱"
      prop="email">
      <el-input
        v-model="dataForm.email">
      </el-input>
    </el-form-item>
    <el-form-item
      label="手机号"
      prop="mobile"
      required>
      <el-input
        v-model="dataForm.mobile">
      </el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" :disabled="edit" @click="submitForm">注册</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
// import { toRaw } from '@vue/reactivity'
import ssqData from '@/common/ssqData2'
export default {
  data() {
    return {
      dataForm: {
        name: '',
        sex: '',
        citizenId: '',
        certType: '',
        major: '',
        certNo: '',
        certNo2: '',
        ssq: [],
        address: '',
        email: '',
        password: '',
        password2: '',
        mobile: '',
      },
      certTypeList: ['执业药师', '从业药师'],
      majorList: ['药学', '中药学', '双证'],
      ssqData: ssqData,

      edit:false
    };
  },
  methods: {
    submitForm () {
      const net = this.$net
      if(this.dataForm.password!=this.dataForm.password2){
        return this.$alert('两次输入的密码不一致')
      }
      // if(!this.dataForm.citizenId.startsWith('22')){
      //   return this.$alert('身份证号默认为吉林省22开头，例如：22xxxxxxxxxxxxxxxx。非吉林省身份证学员，请联系吉林省执业药师协会，核实后再注册学习。')
      // }
      this.$refs['form'].validate((valid) => {
        if (valid) {
          net.register({
            "name": this.dataForm.name,
            "sex": this.dataForm.sex,
            "citizenId": this.dataForm.citizenId,
            "certType": this.dataForm.certType,
            "major": this.dataForm.major,
            "certNo": this.dataForm.certNo,
            "certNo2": this.dataForm.certNo2,
            "addressSheng": this.dataForm.ssq[0],
            "addressShi": this.dataForm.ssq[1],
            "addressQu": this.dataForm.ssq[2],
            "address": this.dataForm.address,
            "email": this.dataForm.email,
            "mobile": this.dataForm.mobile
          }).then(({data})=>{
            if (data.code != 0) {
              this.$alert(data.msg)
              return
            }
            this.$alert('注册成功')
            this.$router.push({ name: 'Login' })
          }).catch((res)=>{
            if(res.msg){
              this.$alert(res.msg )
            }else{
              this.$alert('注册失败，请重试！' )
            }
          })
        } 
      });
    }
  }
};
</script>
<style scoped>
.login-form{
    margin-top: 50px;
    width: 460px
}
</style>
